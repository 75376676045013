/**
 * @module bulma-help
 *
 * @callback bulmaCallback
 * @param {Element} element
 * @param {Event} event
 * @param {Object} [help]
 *
 *
 * @callback simpleCallback
 * @param {Event} event
 */


/**
 * Invoke callback when event is triggered on an element matched by selector.
 * @param {HTMLElement} root
 * @param {String} eventName
 * @param {String} selector
 * @param {bulmaCallback} callback
 * @param {Boolean} [allowDefault]
 */
function live(root, eventName, selector, callback, allowDefault) {
  root.addEventListener(eventName, async function(event) {
    const element = event.target.closest(selector)
    if (element) {
      if (!allowDefault) {
        event.preventDefault()
      }

      await callback(element, event, this)
    }
  })
}


/**
 * Invoke callback if event is triggered outside not on an element
 * matched by the selector.
 * @param {HTMLElement} root
 * @param {String} eventName
 * @param {String} selector
 * @param {bulmaCallback} callback
 */
function avoid(root, eventName, selector, callback) {
  root.addEventListener(eventName, function(event) {
    const element = event.target.closest(selector)
    if (!element) {
      callback(element, event, this)
    }
  })
}


/**
 * @param {Element} root
 * @param {String} eventName
 * @param {simpleCallback} callback
 */
function event(root, eventName, callback) {
  root.addEventListener(eventName, function(event) {
    callback(event)
  })
}


const getLink = function(url, callback) {
  const xhr = new XMLHttpRequest()
  xhr.onload = function () {
    callback(this.responseText)
  }
  xhr.open('GET', url, true)
  xhr.send()
}


/**
  * @param {HTMLFormElement} $form
  * @param callback : called with the html content returned by the server
  */
function sendForm($form, callback, extraParams = [], method='post') {
  const xhr = new XMLHttpRequest()
  xhr.onload = function () {
    callback(this.responseText)
  }
  const url = new URL($form.attributes.action?.value || '', document.location)

  const formdata = new FormData($form)
  for (const [name, value] of extraParams) {
    formdata.append(name, value)
  }

  if (method === 'get') {
    for (const [name, value] of [...formdata]) {
      url.searchParams.append(name, value)
    }
  }

  xhr.open(method, url.toString())

  if (method === 'get') {
    xhr.send()
  } else {
    xhr.send(formdata)
  }
}


const addLoader = function(element, timeout){
  if (element.classList.contains('add-loader')){
    element.classList.add('is-loading')
    if (timeout) {
      setTimeout(() => removeLoader(element), timeout)
    }
  }
}

const getFocusSelector = function(){
  const element = document.activeElement.getAttribute('name')
  if (element) {
    return `[name='${element}']`
  }
}

const setFocus = function(selector){
  if (selector) {
    const element = document.querySelector(selector)
    if (element) {element.focus()}
  }
}

/**
 * Remove .is-loading class from an element
 * @param {Element} element
 */
function removeLoader(element) {
  element.classList.remove('is-loading')
}


const template_parse = function(template, attrs) {
  const subst = ($1, $2) => { return attrs[$2] }
  const html = template.replace(/\$\(([^)]+)?\)/g, subst)
  return html
}

const render_template = function(selector, element){
  const template = document.getElementById(selector).cloneNode(true)
  if (element.hasAttributes()){
    const mapping = {}
    for (const attr of element.attributes) {
      mapping[attr.name] = attr.value
    }
    template.innerHTML = template_parse(template.innerHTML, mapping)
  }
  const node = document.importNode(template.content, true)
  return node
}



/**
  * Replace received html into the html page.
  * @param {Element} root
  * @param {String} selector
  * @param {String} html
  */
function replaceHtml(root, selector, html) {
  const $temp = document.createElement('template')
  $temp.innerHTML = html
  const content = $temp.content

  const change_list = []

  if (selector) {
    const target = root.querySelector(selector)
    if (target === null) {
      console.error(`Unable to replace response content: selector "${selector}" doesn't match any items`)
      return
    }
    change_list.push({segmento: content, target})

  } else {
    for (const segmento of content.children) {
      selector = segmento.dataset.ajaxTarget
      const target = root.querySelector(selector)
      if (target === null) {
        console.error(`Server response chunk has selector "${selector}" which does not match any dom elements. I'm ignoring this chunk: `, segmento)
        continue
      }
      change_list.push({target, segmento})
    }
  }

  for (const elem of change_list) {
    addFlashClass(elem.segmento)
    elem.target.replaceWith(elem.segmento)
  }
}


function buildReplaceHtml(root) {
  return replaceHtml.bind(null, root)
}


const doFocus = function() {
  const selected = document.querySelector('#focused input')
  if (selected){
    selected.focus()
    selected.closest('#focused').removeAttribute('id')
  }
}


const flashClass = function(element) {
  if (typeof(element.hasAttribute) !== 'undefined' && element.hasAttribute('data-flash-class')){
    const classi_css = element.dataset.flashClass.split(' ')
    element.classList.add(...classi_css)
    setTimeout(() => element.classList.remove(...classi_css), 1000)
  }
}

const addFlashClass = function(fragment){
  flashClass(fragment)
  const subs = fragment.querySelectorAll('[data-flash-class]')
  for (const sub of subs) {
    flashClass(sub)
  }
}

function getExtraParams($elem) {
  return Array.from(new URLSearchParams($elem.dataset.vals).entries())
}

/**
  * Asynchronously submit the form element.
  * Replaces applicatin/html response on the document, depending on the value of
  * the attribute `data-async-target` of element.
  *
  * @param {HTMLElement} root
  * @param {HTMLElement} element
  * @param {HTMLFormElement} form
  * @param onReplyCallback
  *
  * called by data-ation when request is async. {@see bulma-events:data-action}
  */
function ajaxFormLoad(root, element, form, onReplyCallback) {
  const target = element.hasAttribute('data-targets-dom')
    ? document
    : root

  const replaceHtml = buildReplaceHtml(target)
  const selector = element.dataset.asyncTarget
  const extraParams = getExtraParams(element)

  sendForm(form, (html) => {
    const focused = getFocusSelector()
    removeLoader(element)
    replaceHtml(selector, html)
    setFocus(focused)
    doFocus()

    if (onReplyCallback) {
      onReplyCallback()
    }
  }, extraParams)
}



const buildAjaxLinkLoad =
  root => {
    return function(element) {
      const target = (element.hasAttribute('data-targets-dom')
        ? document
        : root)

      const replaceHtml = buildReplaceHtml(target)

      const selector = element.dataset.replace
      const url = element.href
      getLink(url, function(html){
        removeLoader(element)
        replaceHtml(selector, html)
      })
    }
  }



/**
 * @param {Element} root
 */
function getRoot(root) {
  return root.querySelector('#shadow-body') || root
}


/**
 * Build help directives using target element as root.
 *
 * @param {HTMLElement} root
 */
function helpOn(root) {
  const help = {}
  return Object.assign({
    getLink,
    sendForm,
    addLoader,
    removeLoader,
    ajaxFormLoad: ajaxFormLoad.bind(null, root),
    ajaxLinkLoad: buildAjaxLinkLoad(root),
    live:         live.bind(help, root),
    avoid:        avoid.bind(help, root),
    event:        event.bind(help, root),
    template_parse,
    render_template,
    getFocusSelector,
    setFocus,
    doFocus,
    getRoot: getRoot.bind(help, root),
  })
}

export {
  addFlashClass,
  ajaxFormLoad,
  replaceHtml,
  getExtraParams,
}
export default helpOn
